.no-error {
	display:none;
}
.text-input-error {
	display:block;
	color: red;
	font-weight: bold;
	font-size: 1.1em;
}
.state-select {
	/* color: #777; */
	color: black;
	font-size: 1em;
	font-weight: normal;
}
.state-select option {
}
.state-select-option {

}
.text-align-right {
	text-align: right;
}
.soft-border {
	border: 1px solid #aaa;
	border-radius: 5px;
}
.shadow-bottom-right {
	box-shadow: 3px 3px 3px #aaa;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.state-select {
		width: 96%;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
