.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.href-link {
  text-decoration: underline;
  color:#1468a1;
  font-weight: bold;
  font-size: .96em;
}
.href-link:hover {
  cursor: pointer;
  color:blue;
}
.create-new-user-link-container {
  margin: 15px;
  padding:15px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
