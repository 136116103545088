div { border: 0px solid green; margin: 2px;}
.wizard-container {
	display:flex;
	flex-direction: column;
}
.wizard-title {
	color: rgb(0,0,82);
	text-shadow: 3px 3px 3px rgb(206,210,215);
	font-size: 3em;
	font-weight: bold;
}
.table-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.account {

}
.name {

}
.user {

}
legend {
	font-size: 2em;
	font-weight: bold;
}
.form-item-table {
	width: 100%;
	border:0px solid black;
}
.form-item-table td:first-child {
	border:0px solid black;
	text-align: right;
}
.form-item-table td:nth-child(2) {
	border:0px solid black;
	text-align: left;
}
.form-title {
	font-size: 1.25em;
	font-weight: bold;
	margin-right: 1em;
	white-space: nowrap;
	flex: 1;
	display: flex;
	flex-direction:column;
	align-items: flex-end;
}
.form-data {
	font-size: 1em;
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.form-data input {
}
.align-right {
	align-self: flex-end;
}
.wizard-nav-button-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.wizard-nav-button {
	margin: 15px;
	font-size: 1.25em;
}
.page-count-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.page-count-label {
	font-size: 1.25em;
	font-weight: bold;
	color: rgb(164, 23, 32);
	margin: 5px;
	padding: 5px;
}
.form-item-container {
	display: flex;
	flex-direction: row;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.wizard-container {
		display:flex;
		flex-direction: column;
	}
	.wizard-title {
		color: rgb(0,0,82);
		text-shadow: 3px 3px 3px rgb(206,210,215);
		font-size: 2em;
		font-weight: bold;
	}
	.table-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.account {

	}
	.name {

	}
	.user {

	}
	legend {
		font-size: 2em;
		font-weight: bold;
	}
	.form-item-table {
		width: 100%;
		border:0px solid black;
	}
	.form-item-table td:first-child {
		border:0px solid black;
		text-align: right;
	}
	.form-item-table td:nth-child(2) {
		border:0px solid black;
		text-align: left;
	}
	.form-title {
		font-size: 1.25em;
		font-weight: bold;
		margin-right: 1em;
		white-space: nowrap;
		flex: 1;
		display: flex;
		flex-direction:column;
		align-items: center;
	}
	.form-data {
		font-size: 1em;
		width: 100%;
		white-space: nowrap;
		flex: 1;
		display: flex;
		flex-direction:column;
		align-items: center;
	}
	.form-data input {
	}
	.align-right {
		align-self: flex-end;
	}
	.wizard-nav-button-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.wizard-nav-button {
		margin: 15px;
		font-size: 1.25em;
	}
	.page-count-label {
		font-size: 1.25em;
		font-weight: bold;
		color: rgb(164, 23, 32);
	}
	.form-item-container {
		display: flex;
		flex-direction: column;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.wizard-container {
		display:flex;
		flex-direction: column;
	}
	.wizard-title {
		color: rgb(0,0,82);
		text-shadow: 3px 3px 3px rgb(206,210,215);
		font-size: 3em;
		font-weight: bold;
	}
	.table-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.account {

	}
	.name {

	}
	.user {

	}
	legend {
		font-size: 2em;
		font-weight: bold;
	}
	.form-item-table {
		width: 100%;
		border:0px solid black;
	}
	.form-item-table td:first-child {
		border:0px solid black;
		text-align: right;
	}
	.form-item-table td:nth-child(2) {
		border:0px solid black;
		text-align: left;
	}
	.form-title {
		font-size: 1.25em;
		font-weight: bold;
		margin-right: 1em;
	}
	.form-data {
		font-size: 1em;
		width: 100%;
	}
	.form-data input {
	}
	.align-right {
		align-self: flex-end;
	}
	.wizard-nav-button-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.wizard-nav-button {
		margin: 15px;
		font-size: 1.25em;
	}
	.page-count-label {
		font-size: 1.25em;
		font-weight: bold;
		color: rgb(164, 23, 32);
	}
	.form-item-container {
		display: flex;
		flex-direction: row;
	}
}

.red-color {
	color:red;
}
.inactive-link {
	color: gray;
	cursor: default;
	text-decoration: none;
}
.inactive-link:hover {
	color: gray;
}
