.budget-buttons-container {
	background-color: inherit;
}
.bInfo-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.bInfo{
	display: flex;
	flex-direction: column;
	border: 1px solid black;
}
.bName-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border: 1px solid black;
}
.bName-title {
	font-weight: bold;
	margin-right: 5px;
	border: 0px solid black;
}
.bName-info {
	border: 0px solid black;
}
.bKey-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border: 1px solid black;
}
.bKey-title {
	font-weight: bold;
	margin-right: 5px;
	border: 0px solid black;
}
.bKey-info {
	border: 0px solid black;
}
