.wizard-container {
	display:flex;
	flex-direction: column;
}
.wizard-title {
	color: rgb(0,0,82);
	text-shadow: 3px 3px 3px rgb(206,210,215);
	font-size: 3em;
	font-weight: bold;
}
.wizard-nav-button-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.wizard-nav-button {
	margin: 15px;
	font-size: 1.25em;
}
@media only screen and (max-width: 600px) {
	.wizard-container {
		display:flex;
		flex-direction: column;
	}
	.wizard-title {
		color: rgb(0,0,82);
		text-shadow: 3px 3px 3px rgb(206,210,215);
		font-size: 2em;
		font-weight: bold;
	}
	.wizard-nav-button-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.wizard-nav-button {
		margin: 15px;
		font-size: 1.25em;
	}
}/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.wizard-container {
		display:flex;
		flex-direction: column;
	}
	.wizard-title {
		color: rgb(0,0,82);
		text-shadow: 3px 3px 3px rgb(206,210,215);
		font-size: 3em;
		font-weight: bold;
	}
	.wizard-nav-button-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.wizard-nav-button {
		margin: 15px;
		font-size: 1.25em;
	}
}

.red-color {
	color:red;
}
.inactive-link {
	color: gray;
	cursor: default;
	text-decoration: none;
}
.inactive-link:hover {
	color: gray;
}
