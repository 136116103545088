#root{
  text-align: center;
  /* display: block; */
  background: #fafafa;
  height: 100%;
}
.container{
	display: flex;
	flex-direction: column;
	height: 100%;
}
.main-content{
	flex:1;
	background-color:white;
	overflow-y: scroll
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  border: 0px solid red;
}
.mini {
  height: 3vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainMenu-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: #dddddd;
	padding: 3px 1px;
}
.mainMenu-button {
	display: block;
	border-top: 1px solid rgb(0,0,82);
	/* background: #1468a1;
	background: -webkit-gradient(linear, left top, left bottom, from(#1468a1), to(#547f9c));
	background: -webkit-linear-gradient(top, #1468a1, #547f9c);
	background: -moz-linear-gradient(top, #1468a1, #547f9c);
	background: -ms-linear-gradient(top, #1468a1, #547f9c);
	background: -o-linear-gradient(top, #1468a1, #547f9c); */
	padding: 2.5px 5px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
	-moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
	box-shadow: rgba(0,0,0,1) 0 1px 0;
	text-shadow: rgba(0,0,0,.4) 0 1px 0;
	color: rgb(206,210,215);
	font-size: 24px;
	font-family: 'Lucida Grande', Helvetica, Arial, Sans-Serif;
	text-decoration: none;
	vertical-align: middle;
	margin: 1px 5px;
}
.mainMenu-button:hover {
	border-top-color: #28597a;
	background: #28597a;
	color: #ccc;
}
.mainMenu-button:active {
	border-top-color: #1b435e;
	background: #1b435e;
}


.userMenu-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: #dddddd;
	padding: 3px 1px;
}
.userMenu-button {
	display: block;
	border-top: 1px solid #96d1f8;
	/* background: #1468a1;
	background: -webkit-gradient(linear, left top, left bottom, from(#1468a1), to(#547f9c));
	background: -webkit-linear-gradient(top, #1468a1, #547f9c);
	background: -moz-linear-gradient(top, #1468a1, #547f9c);
	background: -ms-linear-gradient(top, #1468a1, #547f9c);
	background: -o-linear-gradient(top, #1468a1, #547f9c); */
	padding: 2.5px 5px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
	-moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
	box-shadow: rgba(0,0,0,1) 0 1px 0;
	text-shadow: rgba(0,0,0,.4) 0 1px 0;
	color: white;
	font-size: 20px;
	font-family: 'Lucida Grande', Helvetica, Arial, Sans-Serif;
	text-decoration: none;
	vertical-align: middle;
	margin: 1px 5px;
}
.userMenu-button:hover {
	border-top-color: #28597a;
	background: #28597a;
	color: #ccc;
}
.userMenu-button:active {
	border-top-color: #1b435e;
	background: #1b435e;
}
.contextMenu-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	background-color: #dddddd;
	padding: 3px 1px;
}
.contextMenu-button {
	display: block;
	border-top: 1px solid #96d1f8;
	/* background: #1468a1;
	background: -webkit-gradient(linear, left top, left bottom, from(#1468a1), to(#547f9c));
	background: -webkit-linear-gradient(top, #1468a1, #547f9c);
	background: -moz-linear-gradient(top, #1468a1, #547f9c);
	background: -ms-linear-gradient(top, #1468a1, #547f9c);
	background: -o-linear-gradient(top, #1468a1, #547f9c); */
	padding: 2.5px 5px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
	-moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
	box-shadow: rgba(0,0,0,1) 0 1px 0;
	text-shadow: rgba(0,0,0,.4) 0 1px 0;
	color: white;
	font-size: 16px;
	font-family: 'Lucida Grande', Helvetica, Arial, Sans-Serif;
	text-decoration: none;
	vertical-align: middle;
	margin: 1px 5px;
}
.contextMenu-button:hover {
	border-top-color: #28597a;
	background: #28597a;
	color: #ccc;
}
.contextMenu-button:active {
	border-top-color: #1b435e;
	background: #1b435e;
}
.footerMenu-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #dddddd;
	padding: 2px 1px;
}
.footerMenu-button {
	display: block;
	border-top: 1px solid #96d1f8;
	/* background: #1468a1;
	background: -webkit-gradient(linear, left top, left bottom, from(#1468a1), to(#547f9c));
	background: -webkit-linear-gradient(top, #1468a1, #547f9c);
	background: -moz-linear-gradient(top, #1468a1, #547f9c);
	background: -ms-linear-gradient(top, #1468a1, #547f9c);
	background: -o-linear-gradient(top, #1468a1, #547f9c); */
	padding: 2px 4px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
	-moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
	box-shadow: rgba(0,0,0,1) 0 1px 0;
	text-shadow: rgba(0,0,0,.4) 0 1px 0;
	color: white;
	font-size: 14px;
	font-family: 'Lucida Grande', Helvetica, Arial, Sans-Serif;
	font-weight: bold;
	text-decoration: none;
	vertical-align: middle;
	margin: 1px 2px;
	height: 20px;
}
.footerMenu-button:hover {
	border-top-color: #28597a;
	background: #28597a;
	color: #ccc;
}
.footerMenu-button:active {
	border-top-color: #1b435e;
	background: #1b435e;
}

.title {
	font-weight: bold;
}
.menu {
	color: black;
}
.context {
	font-size: 1em;
}

.page-title {
	font-size: 2em;
	font-weight: bold;
	text-decoration: underline;
	margin-bottom: 15px;
}





.section-container {
  display: block;
  flex: 5;
  overflow-y: scroll;
  margin:5px;
  padding:1%;
  background-color:white;
  box-shadow:0px 0px 5px black;
}
.header {
  display:flex;
  flex:1;
  flex-direction: row;
  border: 0px solid green;
  background-color:#dddddd;
  padding:1%;
  margin: 5px;
  color:white;
  box-shadow:0px 0px 5px black;
  border-radius: 3px;
  overflow-y: visible;
}
.logo-container {
  display: flex;
  flex-direction: column;
}
.navigation-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.action-container {
  display: flex;
  flex-direction: column;
}
.footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 0px solid green;
  border-radius: 3px;
	background-color:#dddddd;
	color:white;
  box-shadow:0px 0px 5px black;
  overflow-y: visible;
}
.logo-container .mini {
  display: flex;
  flex-direction: column;
}
.copyright-container {
  display: flex;
  flex-direction: column;
}
.dashboard {

}
.preferences {

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
